
jQuery(document).ready(function ()
{
    if ($('body#page-uid-710').length > 0) {
        initializeReadspeakerURL();
    }
});

//*replace url parameter with current URL. tourismus event pages: extern pages (not TYPO3)
function initializeReadspeakerURL() {
    var readspeakerButton = $('body#page-uid-710 a.rsbtn_play');
    if (readspeakerButton) {
        var oldURL = readspeakerButton.attr('href');
        var newURL = replace_Parameter_in_URL(oldURL, 'url', window.location.href);
        readspeakerButton.attr('href', newURL);
    }
}

function replace_Parameter_in_URL(url, parameterName, newValue)
{
    //*replace query-string parameter with new value
    var re = new RegExp("([?&])" + parameterName + "=.*?(&|$)", "i");
    var separator = url.indexOf('?') !== -1 ? "&" : "?";
    if (url.match(re)) {
        return url.replace(re, '$1' + parameterName + "=" + newValue + '$2');
    }
    return url + separator + parameterName + "=" + newValue;
}