/**
 * Show or hide 'to top' anchor depending on how much was scrolled
 */
window.addEventListener('scroll', function () {
    var $toTopAnchor = $('.js-scrolltop-btn');
    var amountScrolled = 300;

    if ($(window).scrollTop() > amountScrolled) {
        $toTopAnchor.addClass('visible');
    } else {
        $toTopAnchor.removeClass('visible');
    }
});


function toggleContrast() {
    var contrastModeClass = 'p-increased-contrast-mode';
    if(document.body.classList.contains(contrastModeClass)) {
        document.body.classList.remove(contrastModeClass);
        return;
    }
    document.body.classList.add(contrastModeClass);
}

function setLocalStorage(key) {
    const keyIsSet = localStorage.getItem(key);

    // Set or Remove localStorage
    if (keyIsSet) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, '1');
    }
}

function initContrastSwitch() {
    var contrastSwitch = document.querySelector('.m-contrast-switch');
    var contrastSwitchLocalStorageKey = 'contrast-increase';

    // If contrast is set in local Storage, add contrast class to top-level element
    if (localStorage.getItem('contrast-increase')) {
        toggleContrast();
    }

    contrastSwitch.addEventListener('click', function(event) {
        setLocalStorage(contrastSwitchLocalStorageKey);
        toggleContrast();
    });
}

window.addEventListener('load', function(event) {
    initContrastSwitch();
});

$(window).on('load', function () {
    document.getElementsByTagName('body')[0].style.display = 'block';
});

jQuery(document).ready(function () {
    'use strict';
    var $ = jQuery;

    /**
     * open and close search in metanav
     */
    $('.js-search-trigger, .js-search-close').on('click', function (event) {
        event.preventDefault();
        $(this).parents('.m-searchbox').toggleClass('m-searchbox--open');
    });

    /**
     * scroll to top
     */
    $('[data-init="to-top-anchor"]').on('click', function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });

    $('.o-navigation .dropdown a').each(function() {
        $(this).on('click', function() {
            window.location = $(this).attr('href');
        })
    });

    /**
     * initialize slider
     */
    if ($('#slider-hero .o-slider__slide').length > 1) {
        $('#slider-hero').slick({
            loop: true,
            itemsToShow: 1,
            // pager: false,
            adaptiveHeight: true,
            autoplay: true,
            pause: 7000,
            prevArrow: '<div class="slick-navigation">' +
                '<button class="slick-arrow slick-prev a-icon a-icon--triangle-left" aria-label="Previous" type="button">Previous</button>' +
                '</div>',
            nextArrow: '<div class="slick-navigation">' +
                '<button class="slick-arrow slick-next a-icon a-icon--triangle-right" aria-label="Previous" type="button">Next</button>' +
                '</div>',
        });
    }

    var gallery = $('.o-gallery');
    if (gallery.find('.gallery-item').length > 1) {
        var buttonNextText = gallery.data('slider-button-next');
        var buttonPrevText = gallery.data('slider-button-prev');
        var customPagingTo = gallery.data('slider-button-custompaging-to');
        var customPagingText = gallery.data('slider-button-custompaging');

        var breakpoints = {
            'screen-slides-2': 600,
            'screen-slides-3': 1000,
            'screen-slides-4': 1400,
            'screen-slides-5': 1800,
            'screen-slides-6': 2200,
        };

        if (gallery.hasClass('o-gallery--layout-small')) {
            breakpoints = {
                'screen-slides-2': 460,
                'screen-slides-3': 1000,
                'screen-slides-4': 1100,
                'screen-slides-5': 1450,
                'screen-slides-6': 1920,
            };
        }

        var settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            variableWidth: true,
            prevArrow: '<div class="slick-navigation">' +
                '<button class="slick-arrow slick-prev a-icon a-icon--triangle-left" type="button">' + buttonNextText + '</button>' +
                '</div>',
            nextArrow: '<div class="slick-navigation">' +
                '<button class="slick-arrow slick-next a-icon a-icon--triangle-right" type="button">' + buttonPrevText + '</button>' +
                '</div>',
            dots: true,
            dotsClass: 'custom_paging',
            customPaging: function customPaging(slider, i) {
                return (i + 1) + customPagingText;
            },
            responsive: [
                {
                    breakpoint: breakpoints['screen-slides-2'],
                    settings: {
                        slidesToShow: 2,
                        infinite: false,
                        customPaging: function customPaging(slider, i) {
                            return (i + 1) + customPagingTo + (i + 2) + customPagingText;
                        },
                    },
                },
                {
                    breakpoint: breakpoints['screen-slides-3'],
                    settings: {
                        slidesToShow: 3,
                        infinite: false,
                        customPaging: function customPaging(slider, i) {
                            return (i + 1) + customPagingTo + (i + 3) + customPagingText
                        },
                    },
                },
                {
                    breakpoint: breakpoints['screen-slides-4'],
                    settings: {
                        slidesToShow: 4,
                        infinite: false,
                        customPaging: function customPaging(slider, i) {
                            return (i + 1) + customPagingTo + (i + 4) + customPagingText

                        },
                    },
                },
                {
                    breakpoint: breakpoints['screen-slides-5'],
                    settings: {
                        slidesToShow: 5,
                        infinite: false,
                        customPaging: function customPaging(slider, i) {
                            return (i + 1) + customPagingTo + (i + 5) + customPagingText
                        },
                    },
                },
                {
                    breakpoint: breakpoints['screen-slides-6'],
                    settings: {
                        slidesToShow: 6,
                        infinite: false,
                        customPaging: function customPaging(slider, i) {
                            return (i + 1) + customPagingTo + (i + 6) + customPagingText
                        },
                    },
                },
            ],
        };

        gallery.slick(settings);

        gallery.find('a, button').attr('tabindex', '-1');
    }

    $('.o-navigation .m-dropdown-submenu .m-dropdown-toggle').on('click', function(e) {
        e.stopPropagation();
        $(this).parent('.m-dropdown-submenu').toggleClass('open');
    });

    var lightboxTrigger = $('.m-lightbox-trigger');
    if (lightboxTrigger.length) {
        initializePopup(lightboxTrigger);
    }

    // readspeaker
    window.rsConf = {
        general: {
            usePost: true
        }
    };

    window.rsDocReaderConf = {
        lang: 'de_de',
        cid: 'caogi',
        drBeforeLink: false,
        img_alt: document.querySelector('header').getAttribute('data-readspeaker-img-alt'),
    };

    if (document.documentElement.lang === "en") {
        window.rsDocReaderConf.lang = "en_uk";
    }
});

function initializePopup(elem) {
    var type = 'image';
    if (elem.siblings('.o-lightbox').length) {
        type = 'inline';
    }

    $('.o-lightbox-container').each(function () {
        $(this).magnificPopup({
            delegate: '.m-lightbox-trigger',
            type: type,
            closeBtnInside: false,
            gallery: {
                enabled: true,
            },
            callbacks: {
                beforeClose: function() {
                    var video = $(this.content).find('video')[0];
                    if (video) {
                        video.pause();
                    }
                },
            }
        });
    });
}
